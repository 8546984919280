<script>
import { inject, onMounted, ref } from 'vue';
import { propsBinder, remapEvents } from '@vue-leaflet/vue-leaflet/src/utils';
import { props, setup as tileLayerSetup } from '@vue-leaflet/vue-leaflet/src/functions/tileLayer';
// googlemutant have bug, use custom script
import L from 'leaflet';
import './Leaflet.GoogleMutant';

export default {
  name: 'TileLayerGoogle',
  props,
  setup(props, context) {
    const leafletRef = ref({});
    const addLayer = inject('addLayer');
    const { options, methods } = tileLayerSetup(props, leafletRef);

    onMounted(async () => {
      const { DomEvent, setOptions } = await import('leaflet/dist/leaflet-src.esm');
      leafletRef.value = L.gridLayer.googleMutant(options);

      const listeners = remapEvents(context.attrs);
      DomEvent.on(leafletRef.value, listeners);

      propsBinder(methods, leafletRef.value, props, setOptions);
      addLayer({
        ...props,
        ...methods,
        leafletObject: leafletRef.value,
      });
    });
  },
  render() {
    return null;
  },
};
</script>
