<template>
  <div
    :id="elementId"
    class="place"
  >
    <div class="columns">
      <div class="column is-narrow">
        <PanTo
          :id="id"
          :type="type"
        >
          <MapMarker
            :id="id"
            :type="type"
          />
        </PanTo>
      </div>
      <div class="column">
        <h5 class="has-text-black has-text-weight-bold is-size-6">
          {{ place.name }}
        </h5>
        <p class="is-size-7">
          {{ place.rating }}
          <a-rate
            :value="place.rating"
            allow-half
            disabled
          />
          <span
            v-if="place.userRatingsTotal"
            class="has-text-grey m-l-2x"
          >{{ $t('google-reviews', { total: $filters.number(place.userRatingsTotal) }) }}</span>
        </p>
        <p class="has-text-grey">
          {{ types }}
        </p>
        <div
          class="columns"
        >
          <div
            v-if="weekdayText"
            class="column"
          >
            <OpeningHours :weekday-text="weekdayText" />
          </div>
          <div
            v-if="hasDistance"
            class="column"
          >
            <Distance :distance="distance" />
          </div>
        </div>
      </div>
      <div class="column is-4">
        <Photos :items="photos" />
        <p class="has-text-centered">
          <a
            :href="gmapsLink"
            target="_blank"
            rel="noopener noreferrer"
          >{{ $t('More detail') }}</a>
        </p>
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en":{
    "google-reviews": "{total} Google reviews",
    "More detail": "More detail"
  },
  "ja":{
    "google-reviews": "{total} Googleレビュー",
    "More detail": "詳細"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import PanTo from '@/views/simulations/components/search/PanTo';
import Photos from '@/views/simulations/components/search/Photos';
import Distance from '@/views/simulations/components/search/Distance';
import MapMarker from '@/views/simulations/components/search/map/MapMarker';
import { TYPE_GOOGLE } from '@/views/simulations/components/search/api-types';
import OpeningHours from '@/views/simulations/components/search/OpeningHours';

export default {
  name: 'GooglePlaceItem',
  components: {
    PanTo,
    Distance,
    MapMarker,
    OpeningHours,
    Photos,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    place: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      type: TYPE_GOOGLE,
    };
  },
  computed: {
    distance() {
      return this.place.distance ? this.place.distance : null;
    },
    elementId() {
      return `place-0-${this.id}`;
    },
    photos() {
      if (process.env.NODE_ENV === 'development') {
        return [];
      }

      const { photos } = this.place;
      // Because the request exceeds the quota, only one image is used now, https://developers.google.com/places/web-service/photos#place_photo_response
      if (photos && photos.length > 0) {
        return photos[0].url ? [photos[0].url] : [];
      }

      return [];
    },
    types() {
      return this.place.types.join(', ').replace(/_/g, ' ');
    },
    gmapsLink() {
      const baseUrl = 'https://www.google.com/maps/search/';

      return `${baseUrl}?api=1&query=${this.place.name}&query_place_id=${this.place.placeId}`;
    },
    ...mapState('google-map', ['map']),
    weekdayText() {
      if (this.place.openingHours && this.place.openingHours.weekdayText) {
        this.place.openingHours.weekdayText.map((t) => {
          const weekdayArr = t.split(':');

          return weekdayArr.length >= 2
            ? { key: weekdayArr.shift(), value: weekdayArr.join(':') }
            : { key: '', value: t };
        });
      }

      return null;
    },
    hasDistance() {
      return this.place.distance && Object.keys(this.place.distance).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.place {
  padding: 20px 0;
  border-bottom: $card-border;
}
</style>
