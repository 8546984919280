<template>
  <div class="tab-rakuten">
    <LoadingContainer v-if="fetchingQueue.rakuten" />
    <template v-else>
      <div
        v-if="rakutenList.length"
        class="list"
      >
        <RakutenPlaceItem
          v-for="(place, id) in rakutenList"
          :id="id"
          :key="place.place_id"
          :place="place"
          :hotel-room="getRoom(place.hotelBasicInfo.hotelNo)"
        />
      </div>
      <a-empty v-else />
    </template>
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import LoadingContainer from '@/components/LoadingContainer';
import RakutenPlaceItem from '@/views/simulations/components/search/RakutenPlaceItem';

export default {
  name: 'TabRakuten',
  components: {
    LoadingContainer,
    RakutenPlaceItem,
  },
  computed: {
    ...mapGetters('simulation-search', ['rakutenList']),
    ...mapState('simulation-search', ['rooms', 'fetchingQueue']),
  },
  methods: {
    getRoom(hotelNo) {
      return this.rooms && this.rooms[hotelNo] ? this.rooms[hotelNo] : undefined;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-rakuten {
  padding: 20px;

  .list {
    display: block;
  }
}
</style>
