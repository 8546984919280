<template>
  <div class="photos">
    <div
      v-for="(item, index) in formatItems"
      :key="index"
    >
      <div
        v-lazy:background-image="item.url"
        :style="`background-size:${item.backgroundSize}`"
        class="bg"
      />
    </div>
  </div>
</template>

<script>
export default {
  name: 'Photos',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    formatItems() {
      let items = [];

      if (this.items.length > 2) {
        items = this.items.slice(0, 3);
      } else {
        items = [...this.items];
        for (let i = this.items.length; i < 1; i += 1) {
          items.push('');
        }
      }

      return items.map((i) => (typeof i === 'string' ? { url: i, backgroundSize: 'cover' } : i));
    },
  },
};
</script>

<style lang="scss" scoped>
.photos {
  display: flex;
  flex-wrap: wrap;

  div {
    flex: 1 1 50%;
    padding: 5px;

    &:nth-child(1) {
      flex: 1 1 100%;
    }

    .bg {
      width: 100%;
      padding-top: 67%;
      background-position: center center;
      background-size: cover;
      background-color: #C4C4C4;
      background-repeat: no-repeat;

      &[lazy=loading] {
        background-size: 200px 200px !important;
      }
    }
  }
}
</style>
