<template>
  <div
    :id="elementId"
    class="place"
  >
    <div class="columns">
      <div class="column is-narrow">
        <PanTo
          :id="id"
          :type="type"
        >
          <MapMarker
            :id="id"
            :type="type"
          />
        </PanTo>
      </div>
      <div class="column">
        <div class="columns">
          <div class="column">
            <h5 class="has-text-black has-text-weight-bold is-size-6">
              <span class="has-text-grey m-r-2x">{{ place.hotelBasicInfo.hotelNo }}</span>
              {{ place.hotelBasicInfo.hotelName }}
            </h5>
            <p class="has-text-grey">
              {{ $filters.cell(place.hotelBasicInfo.hotelKanaName) }}
            </p>
            <p class="is-size-7">
              {{ $filters.cell(place.hotelBasicInfo.reviewAverage) }}
              <a-rate
                :value="$filters.cell(place.hotelBasicInfo.reviewAverage, '')"
                allow-half
                disabled
              />
              <span
                v-if="place.hotelBasicInfo.reviewCount"
                class="has-text-grey m-l-2x"
              >
                {{ $t('reviews', { count: $filters.number(place.hotelBasicInfo.reviewCount) }) }}
              </span>
            </p>
            <div
              class="columns"
            >
              <div
                class="column"
              >
                <h5 class="has-text-black has-text-weight-bold">
                  {{ $t('Hotel info') }}
                </h5>
                <table>
                  <tr>
                    <td>{{ $t('Hotel Room') }}</td>
                    <td class="value">
                      {{ $filters.cell(place.hotelFacilitiesInfo.hotelRoomNum) }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Hotel Special') }}</td>
                    <td class="value">
                      {{ $filters.cell(place.hotelBasicInfo.hotelSpecial) }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Parking') }}</td>
                    <td class="value">
                      {{ $filters.cell(place.hotelBasicInfo.parkingInformation) }}
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Service') }}</td>
                    <td class="value">
                      {{ $filters.number(place.hotelRatingInfo.serviceAverage) }}/5
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Location') }}</td>
                    <td class="value">
                      {{ $filters.number(place.hotelRatingInfo.locationAverage) }}/5
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Room') }}</td>
                    <td class="value">
                      {{ $filters.number(place.hotelRatingInfo.roomAverage) }}/5
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Equipment') }}</td>
                    <td class="value">
                      {{ $filters.number(place.hotelRatingInfo.equipmentAverage) }}/5
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Bath') }}</td>
                    <td class="value">
                      {{ $filters.number(place.hotelRatingInfo.bathAverage) }}/5
                    </td>
                  </tr>
                  <tr>
                    <td>{{ $t('Meal') }}</td>
                    <td class="value">
                      {{ $filters.number(place.hotelRatingInfo.mealAverage) }}/5
                    </td>
                  </tr>
                </table>
              </div>
              <div
                v-if="hasDistance"
                class="column"
              >
                <Distance :distance="distance" />
              </div>
            </div>
          </div>
          <div class="column is-4">
            <Photos :items="photos" />
          </div>
        </div>

        <!-- hotel links start -->
        <div class="columns is-gapless is-marginless">
          <div class="column is-narrow">
            <div class="hotel-label">
              {{ $t('Hotel info') }}
            </div>
          </div>
          <div class="column">
            <a
              :href="place.hotelBasicInfo.hotelInformationUrl"
              target="_blank"
              rel="noopener noreferrer"
            >{{ place.hotelBasicInfo.hotelInformationUrl }}</a>
          </div>
        </div>
        <div class="columns is-gapless is-marginless">
          <div class="column is-narrow">
            <div class="hotel-label">
              {{ $t('Plan list') }}
            </div>
          </div>
          <div class="column">
            <a
              :href="place.hotelBasicInfo.planListUrl"
              target="_blank"
              rel="noopener noreferrer"
            >{{ place.hotelBasicInfo.planListUrl }}</a>
          </div>
        </div>
        <div class="columns is-gapless is-marginless">
          <div class="column is-narrow">
            <div class="hotel-label">
              {{ $t('Review') }}
            </div>
          </div>
          <div class="column">
            <a
              :href="$filters.cell(place.hotelBasicInfo.reviewUrl, '')"
              target="_blank"
              rel="noopener noreferrer"
            >{{ $filters.cell(place.hotelBasicInfo.reviewUrl) }}</a>
          </div>
        </div>
        <!-- hotel links end -->

        <!-- room price start -->
        <h5
          class="has-text-black has-text-weight-bold is-size-7"
          style="margin-top: 1rem;"
        >
          {{ $t('Room Price') }}
        </h5>
        <div class="columns is-gapless is-marginless is-multiline">
          <template
            v-for="(item, index) in hotelRoomRateInfo"
            :key="index"
          >
            <div class="column is-3">
              {{ item.label }}
            </div>
            <div class="column is-3">
              {{ item.text }}
            </div>
          </template>
        </div>
        <!-- room price end -->
      </div>
    </div>
  </div>
</template>

<i18n>
{
  "en": {
    "reviews": "{count} reviews",
    "Hotel info": "Hotel info",
    "Hotel Room": "Hotel Room",
    "Hotel Special": "Hotel Special",
    "Parking": "Parking",
    "Service": "Service",
    "Location": "Location",
    "Room": "Room",
    "Equipment": "Equipment",
    "Bath": "Bath",
    "Meal": "Meal",
    "Hotel info": "Hotel info",
    "Plan list": "Plan list",
    "Review": "Review",
    "Room Price": "Room Price",
    "Hotel min. charge": "Hotel min. charge"
  },
  "ja": {
    "reviews": "{count} レビュー",
    "Hotel info": "ホテル情報",
    "Hotel Room": "ホテルルーム",
    "Hotel Special": "ホテルスペシャル",
    "Parking": "駐車",
    "Service": "サービス",
    "Location": "ロケーション",
    "Room": "部屋",
    "Equipment": "機器",
    "Bath": "風呂",
    "Meal": "食事",
    "Hotel info": "ホテル情報",
    "Plan list": "計画一覧",
    "Review": "レビュー",
    "Room Price": "料金",
    "Hotel min. charge": "Hotel min. charge"
  }
}
</i18n>

<script>
import NProgress from 'nprogress';
import isEmpty from 'lodash/isEmpty';
import startCase from 'lodash/startCase';
import PanTo from '@/views/simulations/components/search/PanTo';
import Photos from '@/views/simulations/components/search/Photos';
import Distance from '@/views/simulations/components/search/Distance';
import MapMarker from '@/views/simulations/components/search/map/MapMarker';
import { TYPE_RAKUTEN } from '@/views/simulations/components/search/api-types';

NProgress.configure({ showSpinner: false });

export default {
  name: 'RakutenPlaceItem',
  components: {
    PanTo,
    Distance,
    MapMarker,
    Photos,
  },
  props: {
    id: {
      type: Number,
      required: true,
    },
    place: {
      type: Object,
      required: true,
    },
    hotelRoom: {
      type: Object,
      default: undefined,
    },
  },
  data() {
    return {
      type: TYPE_RAKUTEN,
    };
  },
  computed: {
    distance() {
      return this.place.distance ? this.place.distance : null;
    },
    elementId() {
      return `place-1-${this.id}`;
    },
    photos() {
      const data = [];
      const { hotelBasicInfo } = this.place;

      if (hotelBasicInfo.hotelImageUrl && !isEmpty(hotelBasicInfo.hotelImageUrl)) {
        data.push(hotelBasicInfo.hotelImageUrl);
      }

      if (hotelBasicInfo.roomImageUrl && !isEmpty(hotelBasicInfo.roomImageUrl)) {
        data.push(hotelBasicInfo.roomImageUrl);
      }

      if (hotelBasicInfo.hotelMapImageUrl && !isEmpty(hotelBasicInfo.hotelMapImageUrl)) {
        data.push(hotelBasicInfo.hotelMapImageUrl);
      }

      return data;
    },
    hotelRoomRateInfo() {
      const data = [];
      const currency = 'JPY';
      const roomRateInfo = this.hotelRoom;
      const currencyFun = this.$filters.currency;

      data.push({
        label: this.$t('Hotel min. charge'),
        text: currencyFun(this.place.hotelBasicInfo.hotelMinCharge, { currency }),
      });

      if (roomRateInfo) {
        Object.keys(roomRateInfo).forEach((key) => {
          if (!isEmpty(roomRateInfo[key])) {
            const lowest = currencyFun(roomRateInfo[key].lowestCharge, { currency });
            const highest = currencyFun(roomRateInfo[key].highestCharge, { currency });

            data.push({
              label: startCase(key),
              text: `${lowest} - ${highest}`,
            });
          }
        });
      }

      return data;
    },
    hasDistance() {
      return this.place.distance && Object.keys(this.place.distance).length > 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.place {
  padding: 20px 0;
  border-bottom: $card-border;
}

.value {
  padding-left: 1rem;
}

.hotel-label {
  width: 5em;
}
</style>
