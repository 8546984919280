<template>
  <div
    class="toggle-search-button"
    :class="{ isActive: showResultPanel }"
    @click="toggle"
  >
    <svg
      width="6"
      height="12"
      viewBox="0 0 6 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      class="toggle-search-icon"
    >
      <path
        d="M0 12L6 6L-5.24537e-07 0L0 12Z"
        fill="#555555"
      />
    </svg>
  </div>
</template>

<script>
import { mapState } from 'vuex';

export default {
  name: 'ResultPanel',
  computed: {
    ...mapState('simulation-search', ['showResultPanel']),
  },
  methods: {
    toggle() {
      this.$store.commit('simulation-search/SET_SHOW_RESULT_PANEL', !this.showResultPanel);
    },
  },
};
</script>

<style lang="scss" scoped>
.toggle-search-button {
  position: absolute;
  top: 50%;
  right: 0;
  background: #F2F2F2;
  box-shadow: -2px 1px 5px rgba(0, 0, 0, 0.2);
  width: 25px;
  height: 50px;
  margin-top: -25px;
  z-index: 101;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transform: rotate(180deg);
  transition: all ease-in-out 0.2s;

  &.isActive {
    transform: rotate(0deg);
  }
}

.toggle-search-icon {
  transition: all ease-in-out 0.2s;
}
</style>
