import { saveAs } from 'file-saver';

export default {
  methods: {
    downloadPdf(payload) {
      this.$store.commit('SHOW_FULLSCREEN_LOADER');

      this.$store
        .dispatch('simulations/exportSimulation', payload)
        .then(({ data, headers }) => {
          const newBlob = new Blob([data]);
          const filename = headers['content-disposition'].replace('attachment; filename=', '');
          saveAs(newBlob, filename);
        })
        .finally(() => {
          this.$store.commit('HIDE_FULLSCREEN_LOADER');
        });
    },
  },
};
