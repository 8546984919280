<template>
  <div class="open-hours">
    <h5 class="has-text-black has-text-weight-bold">
      {{ $t('Opening hours') }}
    </h5>
    <table>
      <tr
        v-for="(v, k) in weekdayText"
        :key="k"
      >
        <td>{{ v.key }}</td>
        <td class="value">
          {{ v.value }}
        </td>
      </tr>
    </table>
  </div>
</template>

<i18n>
{
  "en":{
    "Opening hours": "Opening hours"
  },
  "ja":{
    "Opening hours": "開館時間"
  }
}
</i18n>

<script>
export default {
  name: 'OpeningHours',
  props: {
    weekdayText: {
      type: Array,
      required: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.value {
  padding-left: 1rem;
}

.open-hours {
  min-width: 17em;
}
</style>
