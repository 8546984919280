export default {
  methods: {
    getSimulation() {
      return new Promise((resolve, reject) => {
        this.$store
          .dispatch('simulations/get', this.getSimulationId())
          .then(({ data }) => resolve(data))
          .catch((error) => reject(error));
      });
    },
    getSimulationId() {
      return this.$route.params.id;
    },
  },
};
