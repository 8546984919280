<template>
  <div>
    <h5 class="has-text-black has-text-weight-bold">
      {{ $t('Distance') }}
    </h5>
    <table>
      <tr
        v-for="(v, k) in distance"
        :key="k"
      >
        <td>
          {{ distanceLabel(k) }}
        </td>
        <td class="value">
          {{ toMins(v) }}
        </td>
      </tr>
    </table>
  </div>
</template>

<i18n>
{
  "en": {
    "Distance": "Distance",
    "mins": "mins",
    "min": "mins",
    "hours": "hour(s)",
    "hour": "hour(s)",
    "On": "On",
    "By": "By",
    "foot": "foot",
    "car": "car",
    "walk": "walk",
    "distance-by": "{prefix} {key}"
  },
  "ja": {
    "Distance": "距離",
    "mins": "分",
    "min": "分",
    "hours": "時",
    "hour": "時",
    "On": "で",
    "By": "で",
    "foot": "足",
    "car": "車",
    "walk": "歩",
    "distance-by": "{key}{prefix}"
  }
}
</i18n>

<script>
import { isEmpty } from 'lodash';

export default {
  name: 'Distance',
  props: {
    distance: {
      type: Object,
      required: true,
    },
  },
  methods: {
    distanceLabel(key) {
      const prefix = key === 'foot' ? this.$t('On') : this.$t('By');

      return this.$t('distance-by', { prefix, key: this.$t(key) });
    },
    toMins(val) {
      if (isEmpty(val)) {
        return '-';
      }

      if (!Number.isNaN(Number(val))) {
        return `${Math.round(val / 60)} ${this.$t('mins')}`;
      }

      const [value, unit] = val.split(' ');

      if (value && unit) {
        return `${value} ${this.$t(unit)}`;
      }

      return val;
    },
  },
};
</script>

<style lang="scss" scoped>
.value {
  padding-left: 1rem;
}
</style>
