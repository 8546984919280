<template>
  <div>
    <a-auto-complete
      v-model:value="address"
      :options="suggestions"
      @change="handleChange"
      @search="delayFetchSuggestions"
      @select="handleSelect"
    >
      <a-input
        :size="size"
        :placeholder="$t('Search an address')"
      />
    </a-auto-complete>
  </div>
</template>

<i18n>
{
  "en": {
    "Search an address": "Search an address",
    "No result": "No result"
  },
  "ja": {
    "Search an address": "Search an address",
    "No result": "No result"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import debounce from 'lodash/debounce';
import { DEFAULT_COUNTRY } from '@/utils/settings';

export default {
  name: 'InputAddress',
  props: {
    value: {
      type: String,
      default() {
        return undefined;
      },
    },
    country: {
      type: String,
      default() {
        return DEFAULT_COUNTRY;
      },
    },
    size: {
      type: String,
      default() {
        return 'large';
      },
    },
  },
  emits: ['update:value', 'change'],
  data() {
    return {
      address: '',
      placeId: undefined,
      coordinates: [],
      suggestions: [],
      isFetchingSuggestion: false,
      placeService: undefined,
      autocompleteService: undefined,
    };
  },
  computed: {
    ...mapState('google-map', ['map']),
  },
  watch: {
    value: {
      immediate: true,
      handler(nv) {
        this.address = nv;
      },
    },
  },
  methods: {
    delayFetchSuggestions: debounce(function (queryString) {
      this.handleFetchSuggestions(queryString);
    }, 500),
    handleFetchSuggestions(queryString) {
      const query = queryString.toLowerCase().trim();
      const blank = [{
        value: this.$t('No result'),
        placeId: undefined,
      }];

      if (query !== '' && query.length > 1) {
        this.isFetchingSuggestion = true;

        const request = { input: query };

        if (this.country) {
          request.componentRestrictions = { country: this.country };
        }

        // Create autocompleteService when needed
        if (!this.autocompleteService) {
          this.autocompleteService = new this.map.places.AutocompleteService();
        }

        this.autocompleteService.getPlacePredictions(request, (results, status) => {
          if (status === this.map.places.PlacesServiceStatus.OK) {
            if (results.length) {
              this.suggestions = results.map((item) => ({
                value: item.description,
                placeId: item.place_id,
              }));
            } else {
              this.suggestions = blank;
            }
          } else {
            if (status !== this.map.places.PlacesServiceStatus.ZERO_RESULTS) {
              this.emitError(`Place API error: ${status}`);
            }
            this.suggestions = blank;
          }
        });
      }
    },
    async handleSelect(value, option) {
      try {
        const { placeId } = option;

        if (placeId) {
          const place = await this.getPlaceDetails(placeId);
          this.coordinates = [place.geometry.location.lat(), place.geometry.location.lng()];
          this.placeId = placeId;
        }

        this.emit();
      } catch (e) {
        this.emitError(e.message);
      }
    },
    getPlaceDetails(placeId) {
      // Create placeService when needed
      if (!this.placeService) {
        this.placeService = new this.map.places.PlacesService(document.createElement('div'));
      }

      return new Promise((resolve, reject) => {
        this.placeService.getDetails({
          placeId,
          fields: ['geometry'],
        }, (place, status) => {
          if (status === this.map.places.PlacesServiceStatus.OK) {
            return resolve(place);
          }
          return reject(`Place API error: ${status}`);
        });
      });
    },
    handleChange() {
      this.$emit('update:value', this.address);
    },
    emit() {
      this.$emit('change', {
        address: this.address,
        coordinates: this.coordinates.join(','),
        placeId: this.placeId,
      });
    },
    emitError(error) {
      if (this.$message) {
        this.$message.error(error);
      }
    },
  },
};
</script>

<style scoped>

</style>
