<template>
  <LMap
    ref="map"
    v-model="zoom"
    v-model:zoom="zoom"
    :options="options"
    :center="center"
    :max-zoom="22"
    style="width: 100%; height: 100%; z-index: 99;"
    @click="onMapClick($event)"
  >
    <LControlZoom
      position="bottomright"
    />
    <TilelayerGoogle
      v-if="map"
      :options="googleOptions"
    />
    <!-- google marker start -->
    <LMarker
      v-for="(place, index) in filteredGoogleList"
      :ref="`marker-${typeGoogle}-${index}`"
      :key="place.placeId"
      :options="markerOptions"
      :lat-lng="[place.geometry.location.lat,place.geometry.location.lng]"
      :z-index-offset="getZIndex(typeGoogle, index)"
      @click="onMarkerClick({ type: 0, index }, $event)"
    >
      <LTooltip :options="tooltipOptions">
        <h6 class="has-text-black has-text-weight-bold is-size-7">
          {{ place.name }}
        </h6>
        <div class="has-text-grey is-size-8">
          {{ typesToString(place.types) }}
        </div>
      </LTooltip>
      <LIcon :icon-size="iconSize">
        <MapMarker
          :id="index"
          :type="typeGoogle"
          :class="getMarkerClass(typeGoogle, index)"
        />
      </LIcon>
    </LMarker>
    <!-- google marker end -->
    <!-- rakuten marker start -->
    <LMarker
      v-for="(place, index) in rakutenList"
      :ref="`marker-${typeRakuten}-${index}`"
      :key="index"
      :options="markerOptions"
      :lat-lng="[place.hotelBasicInfo.latitude,place.hotelBasicInfo.longitude]"
      :z-index-offset="getZIndex(typeRakuten, index)"
      @click="onMarkerClick({ type: 1, index }, $event)"
    >
      <LTooltip :options="tooltipOptions">
        <h6 class="has-text-black has-text-weight-bold is-size-7">
          {{ place.hotelBasicInfo.hotelName }}
        </h6>
        <div class="has-text-grey is-size-8">
          {{ $filters.cell(place.hotelBasicInfo.hotelKanaName) }}
        </div>
      </LTooltip>
      <LIcon :icon-size="iconSize">
        <MapMarker
          :id="index"
          :type="typeRakuten"
          :class="getMarkerClass(typeRakuten, index)"
        />
      </LIcon>
    </LMarker>
    <!-- Rakuten marker end -->
    <!-- POI marker start -->
    <LMarker
      v-if="poiMarker"
      id="poiMarker"
      ref="poi-marker"
      :options="markerOptions"
      :lat-lng="poiMarker"
      :z-index-offset="3000"
      draggable
      @moveend="onPoiMoveend($event)"
    >
      <LPopup>
        <div class="has-text-grey is-size-8">
          {{ address }}
        </div>
      </LPopup>
      <LIcon :icon-size="iconSize">
        <svg
          width="24"
          height="33"
          viewBox="0 0 24 33"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M12 31L11.6289 31.3351L12 31.746L12.3711 31.3351L12 31ZM12 31C12.3711 31.3351 12.3711 31.335 12.3713 31.3349L12.3718 31.3344L12.3734 31.3325L12.3796 31.3257L12.403 31.2995C12.4235 31.2766 12.4536 31.2428 12.4927 31.1985C12.5709 31.11 12.685 30.9797 12.83 30.8115C13.1199 30.475 13.5332 29.9863 14.0289 29.3756C15.0197 28.1548 16.3416 26.4435 17.6645 24.4828C18.9864 22.5235 20.3155 20.306 21.3157 18.0736C22.3129 15.8476 23 13.5699 23 11.5C23 5.41886 18.0811 0.5 12 0.5C5.91886 0.5 1 5.41886 1 11.5C1 13.5699 1.68711 15.8476 2.68432 18.0736C3.68447 20.306 5.01358 22.5235 6.33552 24.4828C7.65842 26.4435 8.98033 28.1548 9.97115 29.3756C10.4668 29.9863 10.8801 30.475 11.17 30.8115C11.315 30.9797 11.4291 31.11 11.5073 31.1985C11.5464 31.2428 11.5765 31.2766 11.597 31.2995L11.6204 31.3257L11.6266 31.3325L11.6282 31.3344L11.6287 31.3349C11.6289 31.335 11.6289 31.3351 12 31ZM12 14.75C11.138 14.75 10.3114 14.4076 9.7019 13.7981C9.09241 13.1886 8.75 12.362 8.75 11.5C8.75 10.638 9.09241 9.8114 9.7019 9.2019C10.3114 8.59241 11.138 8.25 12 8.25C12.862 8.25 13.6886 8.59241 14.2981 9.2019C14.9076 9.8114 15.25 10.638 15.25 11.5C15.25 12.362 14.9076 13.1886 14.2981 13.7981C13.6886 14.4076 12.862 14.75 12 14.75Z"
            fill="#00596C"
            stroke="white"
          />
        </svg>
      </LIcon>
    </LMarker>
    <!-- POI marker end -->
  </LMap>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import debounce from 'lodash/debounce';
import {
  LIcon, LMap, LMarker, LTooltip, LPopup, LControlZoom,
} from '@vue-leaflet/vue-leaflet';
import 'leaflet/dist/leaflet.css';

import MapMarker from '@/views/simulations/components/search/map/MapMarker';
import TilelayerGoogle from './TilelayerGoogle';
import { TYPE_GOOGLE, TYPE_RAKUTEN } from '@/views/simulations/components/search/api-types';

export default {
  name: 'Map',
  components: {
    MapMarker,
    LMap,
    LMarker,
    LIcon,
    TilelayerGoogle,
    LTooltip,
    LPopup,
    LControlZoom,
  },
  props: {
    options: {
      type: Object,
      default() { return {}; },
    },
    address: {
      type: String,
      default: '',
    },
    coordinates: {
      type: String,
      default: '',
    },
    googleOptions: {
      type: Object,
      default() {
        return {
          styles: [
            {
              featureType: 'poi.business',
              stylers: [{ visibility: 'off' }],
            },
            {
              featureType: 'poi.park',
              elementType: 'labels.text',
              stylers: [{ visibility: 'off' }],
            },
          ],
        };
      },
    },
    center: {
      type: Object,
      default() {
        return {
          lat: 36,
          lng: 138,
        };
      },
    },
  },
  emits: ['address-update'],
  data() {
    return {
      zoom: 5,
      iconSize: [24, 33],
      typeGoogle: TYPE_GOOGLE,
      typeRakuten: TYPE_RAKUTEN,
      markerOptions: { riseOnHover: true, riseOffset: 4000 },
      tooltipOptions: { offset: { x: 10, y: 0 } },
      geoCoder: undefined,
      fitBoundsHandler: undefined,
      poiMarker: null,
    };
  },
  computed: {
    ...mapState('google-map', ['map']),
    ...mapState('simulation-search', ['panToPlace', 'showResultPanel']),
    ...mapGetters('simulation-search', ['googleMarkers', 'rakutenMarkers', 'filteredGoogleList', 'rakutenList']),
    coordinateBounds() {
      return [...this.googleMarkers, ...this.rakutenMarkers];
    },
  },
  watch: {
    coordinates: {
      immediate: true,
      handler(nv) {
        this.poiMarker = nv ? nv.split(',') : null;
      },
    },
    coordinateBounds(nV) {
      if (nV && nV.length && this.$refs.map) {
        this.fitBounds();
      }
    },
    center() {
      this.fitBounds();
    },
    showResultPanel() {
      this.fitBounds();
    },
    panToPlace(nV) {
      if (nV) {
        let coordinates;

        switch (nV.type) {
          case TYPE_GOOGLE:
            coordinates = this.googleMarkers[nV.id];
            break;
          case TYPE_RAKUTEN:
            coordinates = this.rakutenMarkers[nV.id];
            break;
          default:
        }

        if (coordinates && this.$refs.map) {
          this.$refs.map.leafletObject.panTo(coordinates);
        }
      }
    },
  },
  beforeUnmount() {
    clearTimeout(this.fitBoundsHandler);
  },
  methods: {
    fitBounds() {
      if (this.coordinateBounds && this.coordinateBounds.length && this.$refs.map) {
        const poi = this.poiMarker ? [this.poiMarker] : [];
        clearTimeout(this.fitBoundsHandler);

        const that = this;
        this.fitBoundsHandler = setTimeout(() => {
          that.$refs.map.leafletObject.invalidateSize();
          that.$refs.map.leafletObject.fitBounds(
            [...that.coordinateBounds, ...poi],
            { padding: [30, 30] },
          );
        }, 500);
      }
    },
    onMarkerClick(selectedPlace) {
      this.$store.commit('simulation-search/SET_SHOW_RESULT_PANEL', true);
      this.$store.commit('simulation-search/SET_SELECTED_PLACE', { ...selectedPlace });
    },
    typesToString(types) {
      return types.join(', ').replace(/_/g, ' ');
    },
    getZIndex(type, index) {
      return this.panToPlace
        && this.panToPlace.type === type
        && this.panToPlace.id === index ? 2000 : 100;
    },
    getMarkerClass(type, index) {
      return this.panToPlace && this.panToPlace.type === type && this.panToPlace.id === index ? 'markerActive' : '';
    },
    onMapClick(event) {
      if (event.latlng) {
        this.poiMarker = [event.latlng.lat, event.latlng.lng];
        this.delayGetAddress(event.latlng);
      }
    },
    onPoiMoveend(event) {
      const coordinates = event.target._latlng;

      this.poiMarker = [coordinates.lat, coordinates.lng];
      this.delayGetAddress(coordinates);
    },
    getCoordinatesAddress(coordinates) {
      try {
        if (!this.geoCoder) {
          this.geoCoder = new this.map.Geocoder();
        }

        const that = this;
        this.geoCoder.geocode({ location: coordinates }, (results, status) => {
          if (status === 'OK') {
            that.$emit('address-update', {
              address: results[0].formatted_address,
              placeId: results[0].place_id,
              coordinates: `${coordinates.lat},${coordinates.lng}`,
            });
          } else {
            that.$message.error(status);
            that.$emit('address-update', null);
          }
        });
      } catch (e) {
        this.$message.error(e.message);
        this.$emit('address-update', null);
      }
    },
    delayGetAddress: debounce(function (coordinates) {
      this.getCoordinatesAddress(coordinates);
    }, 600),
  },
};
</script>

<style lang="scss" scoped>
.markerActive {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}
</style>
