<template>
  <div class="tab">
    <div class="tab-head">
      <a
        v-for="(label, i) in labels"
        :key="i"
        :class="{ active: i === currentTab }"
        @click="changeTab(i)"
      >
        <LoadingOutlined
          v-show="tabLoadings[i]"
          class="m-r"
        />
        {{ $t(label) }}</a>
    </div>
    <div
      id="tab-content"
      class="tab-content"
    >
      <transition name="view-fade">
        <keep-alive>
          <component :is="tabContent" />
        </keep-alive>
      </transition>
    </div>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import animateScrollTo from 'animated-scroll-to';
import LoadingOutlined from '@ant-design/icons-vue/LoadingOutlined';
import TabGoogle from '@/views/simulations/components/search/TabGoogle';
import TabRakuten from '@/views/simulations/components/search/TabRakuten';

export default {
  name: 'SimulationTab',
  components: {
    LoadingOutlined,
  },
  data() {
    return {
      currentTab: 0,
      timerHandle: null,
      labels: [
        'Google Business',
        'Rakuten Travel',
      ],
    };
  },
  computed: {
    tabContent() {
      switch (this.currentTab) {
        case 0:
          return TabGoogle;
        case 1:
          return TabRakuten;
        default:
          return TabGoogle;
      }
    },
    ...mapState('simulation-search', ['selectedPlace']),
    ...mapState('simulation-search', {
      tabLoadings: (state) => [state.fetchingQueue.google, state.fetchingQueue.rakuten],
    }),
  },
  watch: {
    selectedPlace(nv) {
      if (nv) {
        if (nv.type === this.changeTab) {
          animateScrollTo(document.querySelector(`#place-${nv.type}-${nv.index}`), {
            elementToScroll: this.$el.querySelector('#tab-content'),
          });
        } else {
          this.changeTab(nv.type);
          this.$nextTick(() => {
            clearTimeout(this.timerHandle);

            this.timerHandle = setTimeout(() => {
              animateScrollTo(document.querySelector(`#place-${nv.type}-${nv.index}`), {
                elementToScroll: this.$el.querySelector('#tab-content'),
              });
            }, 300);
          });
        }
      }
    },
  },
  methods: {
    changeTab(i) {
      this.currentTab = i;
    },
  },
};
</script>

<style lang="scss" scoped>
.tab {
  height: 100%;
}

.tab-head {
  height: 65px;
  background-color: $primary;
  padding: 0 20px;
  display: flex;

  a {
    display: block;
    font-weight: bold;
    font-size: 16px;
    line-height: 20px;
    padding: 25px 20px 20px 25px;
    color: $white;
    position: relative;
    margin-right: 15px;
    opacity: 0.5;
    transition: all ease-in-out 0.2s;
    user-select: none;

    &:after {
      position: absolute;
      content: '';
      width: 100%;
      height: 4px;
      background-color: #DD4747;
      bottom: 0;
      left: 0;
    }

    &:nth-child(2) {
      &:after {
        background-color: #00B900;
      }
    }

    &:nth-child(3) {
      &:after {
        background-color: #FF9736;
      }
    }

    &.active {
      opacity: 1;

      &:after {
        transform: scaleY(2);
      }
    }
  }
}

.tab-content {
  height: calc(100% - 65px);
  overflow-y: auto;
}
</style>
