<template>
  <span @click="setPanTo">
    <slot />
  </span>
</template>

<script>
export default {
  name: 'PanTo',
  props: {
    id: {
      type: Number,
      required: true,
    },
    type: {
      type: String,
      required: true,
    },
  },
  methods: {
    setPanTo() {
      this.$store.commit('simulation-search/SET_PAN_TO_PLACE', {
        type: this.type,
        id: this.id,
      });
    },
  },
};
</script>

<style scoped>

</style>
