<template>
  <div class="tab-google">
    <div class="columns is-mini-gap">
      <div
        class="column is-narrow"
        style="line-height: 40px;"
      >
        {{ $t('Type') }}
      </div>
      <div class="column">
        <a-select
          v-model:value="type"
          allow-clear
          mode="multiple"
          size="large"
          placeholder="All"
          style="width: 100%; max-width: 500px;"
        >
          <a-select-option
            v-for="(t, i) in googleTypeOptions"
            :key="i"
            :value="t"
          >
            {{ formatType(t) }}
          </a-select-option>
        </a-select>
      </div>
    </div>
    <LoadingContainer v-if="fetchingQueue.google" />
    <template v-else>
      <div
        v-if="filteredGoogleList.length"
        class="list"
      >
        <GooglePlaceItem
          v-for="(place,id) in filteredGoogleList"
          :id="id"
          :key="place.place_id"
          :place="place"
        />
      </div>
      <a-empty v-else />
    </template>
  </div>
</template>

<i18n>
{
  "en": {
    "Type": "Type"
  },
  "ja": {
    "Type": "タイプ"
  }
}
</i18n>

<script>
import { mapGetters, mapState } from 'vuex';
import LoadingContainer from '@/components/LoadingContainer';
import GooglePlaceItem from '@/views/simulations/components/search/GooglePlaceItem';

export default {
  name: 'TabGoogle',
  components: { LoadingContainer, GooglePlaceItem },
  computed: {
    ...mapGetters('simulation-search', ['filteredGoogleList', 'googleTypeOptions']),
    ...mapState('simulation-search', ['googleType', 'fetchingQueue']),
    type: {
      get() {
        return this.googleType;
      },
      set(nv) {
        this.$store.commit('simulation-search/SET_GOOGLE_TYPE', nv);
      },
    },
  },
  methods: {
    formatType(type) {
      return type.replace(/_/g, ' ');
    },
  },
};
</script>

<style lang="scss" scoped>
.tab-google {
  padding: 20px;

  .list {
    display: block;
  }
}
</style>
