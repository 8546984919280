<template>
  <div class="search">
    <div
      class="map-wrapper"
      :class="{ full: !showResultPanel }"
    >
      <a-spin
        v-if="!isMapReady"
        size="large"
      />
      <template v-else>
        <ResultPanel />
        <slot name="form" />
        <Map
          v-if="map"
          :center="mapCenter"
          :options="mapOptions"
          :coordinates="form.coordinates"
          :address="form.address"
          @address-update="onAddressUpdate"
        />
      </template>
    </div>
    <transition name="view-slide-left">
      <div
        v-show="showResultPanel"
        class="content-wrapper"
      >
        <LoadingContainer
          v-if="isFetching && !results"
        />
        <template v-else>
          <div
            v-if="!results"
            class="search-empty"
          >
            {{ $t('search-empty') }}
          </div>
          <SimulationTab v-else />
        </template>
      </div>
    </transition>
  </div>
</template>

<i18n>
{
  "en": {
    "search-empty": "Please enter an address"
  },
  "ja": {
    "search-empty": "アドレスを入力してください"
  }
}
</i18n>

<script>
import { mapState } from 'vuex';
import LoadingContainer from '@/components/LoadingContainer';
import Map from '@/views/simulations/components/search/map/Map';
import SimulationTab from '@/views/simulations/components/search/SimulationTab';
import ResultPanel from '@/views/simulations/components/search/ResultPanel';

export default {
  name: 'SearchMap',
  components: {
    LoadingContainer,
    Map,
    SimulationTab,
    ResultPanel,
  },
  props: {
    request: {
      type: Object,
      default() { return null; },
    },
    isMapReady: {
      type: Boolean,
    },
    mapOptions: {
      type: Object,
      default() { return {}; },
    },
  },
  emits: ['address-update'],
  data() {
    return {
      form: {},
    };
  },
  computed: {
    ...mapState('google-map', ['map', 'mapCenter']),
    ...mapState('simulation-search', ['isFetching', 'results', 'showResultPanel']),
  },
  watch: {
    request: {
      immediate: true,
      deep: true,
      handler(nv) {
        const { spdb, ...simulation } = nv;
        this.form = JSON.parse(JSON.stringify(simulation));
      },
    },
  },
  methods: {
    onAddressUpdate(address) {
      this.$emit('address-update', address);
    },
  },
};
</script>

<style lang="scss" scoped>
.search {
  display: flex;
  height: calc(100vh - 65px);
}

.map-wrapper {
  flex: 1 1 50%;
  max-width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  transition: all ease-in-out 0.2s;

  &.full {
    flex: 1 1 100%;
    max-width: 100%;
    height: 100vh;
  }
}

.content-wrapper {
  flex: 1 1 50%;
  max-width: 50%;
}

.search-empty {
  padding: 4.5rem 1.5rem;
  text-align: center;
  color: #888888;
  font-weight: bold;
}
</style>
